
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"
import formatTools from "../../../lib/formatTools"

const ENTITYID="messaging"

const searchFields = [
	{"label":"Name", "dbfield": "messaging_name", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "messaging_stat", "type": "checkbox", "filtertype": "checkbox"}
];


const formFields = [
	{"label":"Hotline", "field": "messaging_name", "value": "", "input": "textbox", "mode": "normal"},
	{"label":"Active", "field": "messaging_stat", "value": "", "input": "checkbox", "mode": "normal"}
];

const subformFields = [
	{
		"subformid": "messagingitem",
		"label": "Recipients",
		"table": "messagingitem",
		"sort": "messagingitem_value",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 20,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email / Mobile",
				"field": "messagingitem_value",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required",
				"info": "Use 11-digit form for mobile e.g.09xxNNNNNNN"
			},
			{
				"label": "Active",
				"field": "messagingitem_stat",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "messagingitem_note",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
				"info": "SMS Notifications are limited"
			}
		],
		"footerlist": [
		]
	}
];




const MessagingPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");


	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < subformFields.length) {
			if (subformFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}

	function subformValidateContact(subform)
	{
		var rowidx = 0;
		var colidx = 0;
		while (rowidx < subform.length) {
			colidx = 0;
			while (colidx < subform[rowidx].length) {
				if (subform[rowidx][colidx].field === "messagingitem_value") {
					if (formatTools.validateEmail(subform[rowidx][colidx].value) === false) {
						if (formatTools.validateSMSNumber(subform[rowidx][colidx].value) === false) {
							return {"status":"Error", "message":"Email/Mobile Number "+subform[rowidx][colidx].value};
						}
					}
				}
				colidx++;
			}
			rowidx++;
		}
		return {"status":"OK"};
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var subformidx = findSubformIdxByTablename("messagingitem");
		if (subformidx>=0) {
			var tmpres = subformValidateContact(subformlist[subformidx]);
			if (tmpres.status !== "OK") {
				return tmpres;
			}
		}

		return {"status":"OK"};
	}


	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["messaging_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}
				customValidateValues={formValidateValues}
				token={token} />
		</Layout>

}


export default MessagingPage;
